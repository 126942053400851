import React from 'react'
import '../styles/Main.css'
import ContentRight from './ContentRight'
import ContentLeft from './ContentLeft'
import { tsParticles } from "https://cdn.jsdelivr.net/npm/@tsparticles/engine@3.1.0/+esm";
import { loadAll } from "https://cdn.jsdelivr.net/npm/@tsparticles/all@3.1.0/+esm";

function Main() {

 //Particles for firefly effect:
  async function loadParticles(options) {
    await loadAll(tsParticles);
  
    await tsParticles.load({ id: "tsparticles", options });
  }
  
  const configs = {
    particles: {
      number: {
        value: 50
      },
      color: {
        value: "#d9d18d"
      },
      links: {
        enable: false,
      },
      shape: {
        type: "circle"
      },
      opacity: {
        value: 0.5,
        random: true,
        anim: {
          enable: true,
          speed: 3,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: {
          min: 0.8,
          max: 2
        },
        random: true,
        anim: {
          enable: true,
          speed: 0.3,
          size_min: 0.1,
          sync: false
        }
      },
      move: {
        enable: true,
        speed: 1
      },
      life: {
        duration: {
          sync: false,
          value: 10
        },
        count: 0,
        delay: {
          random: {
            enable: true,
            minimumValue: 0.5
          },
          value: 1
        }
      }
    }
  };
  
  loadParticles(configs);
  




  return (
    <div className='main-wrapper'>
      <div id="tsparticles"></div>
      <div className='monitor'>
        <div className='overlay'>
          <div className='frame'>
                <ContentLeft />
                <ContentRight />
            </div>
        </div>
      </div>
      <div>
        <div className='stand'></div>
        <div className='stand'></div>
      </div>
      <div className='pole'></div>
    </div>
  )
}

export default Main