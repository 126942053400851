import React, { forwardRef } from 'react'
import '../styles/Main.css'
import ProjectModal from './ProjectModal';

const Projects = forwardRef((props, ref) => {
  //Modal state:
  const [modalOpen, setModalOpen] = React.useState('project'); // to be used later

  return (
    <div className='sub-content' ref={ref}>
        <div className='intro-smallest internalsub-content'>
            <strong className='projectTitle' onClick={() => setModalOpen('richRecovery')}>richRecovery</strong>
            <br /><em>JavaScript, React, Express.js, MySQL, Git</em>
            {/* <br /><a href='https://github.com/achenalia/saiga' target="_blank" rel="noreferrer">https://github.com/achenalia/saiga</a> */}
                <p>
                An at-home charting suite to allow layman providers the ability to document their loved one's vital signs, ensure time-crucial medication
                administration, take notes on overall patient status, set reminders based on the patient's individual care plan, and allow disseminating of recovery information
                with other members of the family without individual check-ins.
                </p>
                {/* {modalOpen === ('richRecovery') ? <ProjectModal projectName={'richRecovery'}/> : null} */}
        </div>
        <div className='intro-smallest internalsub-content'>
            <strong style={{cursor: 'pointer'}} className='projectTitle' onClick={() => setModalOpen('Saiga')}>Saiga</strong>
            <br /><em>Typescript, React, Git</em>
            <br /><a href='https://github.com/achenalia/saiga' target="_blank" rel="noreferrer">https://github.com/achenalia/saiga</a>
                <p>
                Saiga transforms your new tab page into a personal study companion. 
                Built with TypeScript and React, this interactive extension replaces the default new tab experience with a search engine selector designed to keep you 
                focused on what matters most—your learning. Whether you're researching a topic or diving into your studies, 
                Saiga empowers you to stay on track with an intuitive, learning-first interface tailored to your needs, adapting further as new features are added.
                </p>
                {/* {modalOpen === ('Saiga') ? <ProjectModal projectName={'Saiga'}/> : null} */}
        </div>
        <div className='intro-smallest internalsub-content'>
            <strong className='projectTitle' onClick={() => setModalOpen('EmoryConnect')}>Emory Connect</strong> 
            <br /><em>Python, Django, React, Next.js, PostgreSQL, Git</em>
            <br /><a href='https://github.com/achenalia/EmoryConnect' target="_blank" rel="noreferrer">https://github.com/achenalia/EmoryConnect</a>
                <p>
                A dynamic networking platform designed to foster connections among Emory University computer science students,
                algorithmically matching them with eachother based on shared development experience and interests. In addition to the matching system,
                Emory Connect provided a space for collaboration, project sharing, and skill-building within the university community.
                </p>
                {/* {modalOpen === ('EmoryConnect')? <ProjectModal projectName={'EmoryConnect'}/> : null} */}
        </div>
        <div className='intro-smallest internalsub-content'>
            <strong className='projectTitle' onClick={() => setModalOpen('MPoxEval')}>MPoxEval</strong> 
            <br /><em>Python, Scikit-Learn, Keras, Git</em>
            <br /><a href='https://github.com/achenalia/MPoxEval' target="_blank" rel="noreferrer">https://github.com/achenalia/MPoxEval</a>
                <p>
                A local CLI tool which utilizes an Artificial Neural Network to assess symptoms and provide
                recommendations on Monkey-Pox testing through user input and machine learning to make predictions regarding
                the patient’s monkey-pox risk and whether to seek medical attention based on their responses.
                </p>
                {/* {modalOpen === ('MPoxEval') ? <ProjectModal projectName={'MPoxEval'} /> : null} */}
        </div>
        {/* {modalOpen ? console.log('modal open') : console.log('modal closed')} */}
    </div>
  )
})

export default Projects