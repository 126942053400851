import React from 'react'
import '../styles/Main.css'

function ProjectModal({projectName}) {

  return (
    <div className='modal-wrapper'>
        <div className='modal-content'>
            <p>
              {<img src={`./images/${projectName}.png`} alt={`${projectName}`} />}
            </p>
        </div>
    </div>
  )
}

export default ProjectModal