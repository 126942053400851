import React, { forwardRef } from 'react'
import '../styles/Main.css'

const Experience = forwardRef((props, ref) => {
  return (
    <div className='sub-content' ref={ref}>
        <div className='intro-smallest internalsub-content'>
            <strong>Lead Full-Stack Developer</strong> | <em>richRecovery</em>
            <p>
                • Led development and maintenance of richRecovery’s web application, designed and implemented front-end and back-end features for the platform, 
                and defined the ICP for the software as a service (SaaS) marketing strategy,
                <br />
                <br />
                • Applied HIPAA-compliant data management of sensitive patient information using PostgreSQL and Express.js while optionally 
                allowing patients to share recovery status with their family and healthcare providers.
                <br />
                <br />
                • Implemented informed warnings to alert users when the patient’s vitals are out of typical range and if it may be
                advisable to get medical attention.
            </p>
        </div>
        <div className='intro-smallest internalsub-content'>
            <strong>Backend Developer & Cloud Architect</strong> | <em>Emory Connect</em>
            <p>
                • Developed and maintained backend functionality using Django and Amazon Web Services (AWS) for a
                student-oriented networking platform with over 100 active users.
                <br />
                <br />
                • Integrated a PostgreSQL database to manage user data, including projects, connections, matches, and skill sets
                comprising over 4,000 options.
                <br />
                <br />
                • Implemented a matching algorithm to connect computer science majors based on similar development experiences
                and interests.
            </p>
        </div>
    </div>
  )
})

export default Experience