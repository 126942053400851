import React, { forwardRef } from 'react'
import '../styles/Main.css'

const About = forwardRef((props, ref) => {
  return (
    <div className='sub-content' ref={ref}>
      <div className='intro-smallest'>
        <p>
            I am an <strong>EMT</strong> turned <strong>Full-Stack Developer</strong> with particular interest in
            building innovative healthcare solutions to improve the lives of patients and providers alike.
        </p>
        <p>
            Outside of my healthcare focus, my expertise spans various programming languages and frameworks, enabling me to build <strong>scalable</strong>, <strong>user-focused</strong> applications
            for any purpose.
        </p>
        <p>
            I thrive in collaborative, <strong>high-pressure</strong> environments where I am able to make use of my skills and <strong>adaptability</strong> to create impactful solutions 
            which drive project success.
        </p>
        <p>
            I have a passion for <strong>learning</strong> and am continuously seeking to improve my skills and knowledge. Here are <em>some</em> of my current <strong>technical skills</strong>:
            <br />
            <br />
            <strong>Languages:</strong><br />
            JavaScript, TypeScript, Rust, Java, Python, Lua, HTML/CSS, C#, SQL, Golang, R
            <br />
            <strong>Frameworks:</strong><br />
            React, Electron, Node.js, Django, Next.js, Express.js, Tailwind CSS
            <br />
            <strong>Development and Database Management Tools:</strong><br />
            Git/Github, Linux, VSCode, IntelliJ, PyCharm, Docker, Amazon Web Services (EC2, S3, Route53), PostgreSQL, MySQL
            <br />
        </p>
      </div>
    </div>
  )
})

export default About