import React, { useState, useRef, useEffect } from 'react';
import '../styles/Main.css';
import About from './About';
import Projects from './Projects';
import Experience from './Experience';

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

function ContentRight() {
  const [currentSection, setCurrentSection] = useState('about');
  const containerRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const experienceRef = useRef(null);


  const executeScroll = (section) => {
    const targetRef = {
      'about': aboutRef,
      'projects': projectsRef,
      'experience': experienceRef,
    }[section];

    if (containerRef.current && targetRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const targetRect = targetRef.current.getBoundingClientRect();

      containerRef.current.scrollTo({
        top: targetRect.top - containerRect.top + containerRef.current.scrollTop,
        behavior: 'smooth',
      });

      setCurrentSection(section);
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const containerHeight = containerRef.current.clientHeight;

      const aboutTop = aboutRef.current.getBoundingClientRect().top - containerTop;
      const projectsTop = projectsRef.current.getBoundingClientRect().top - containerTop;
      const experienceTop = experienceRef.current.getBoundingClientRect().top - containerTop;

      const aboutMid = aboutTop * 2;
      const projectsMid = projectsTop * 2;
      const experienceMid = experienceTop * 2;
        // above values are still being tweaked
      const scrollPosition = containerRef.current.scrollTop + containerHeight / 2;

      if (scrollPosition >= experienceMid) {
        setCurrentSection('experience');
      } else if (scrollPosition >= projectsMid) {
        setCurrentSection('projects');
      } else {
        setCurrentSection('about');
      }
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 100); // delay the highlighting of section

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', debouncedHandleScroll);
      handleScroll();

      return () => {
        container.removeEventListener('scroll', debouncedHandleScroll);
      };
    }
  }, []);

  return (
    <div className='frame-right'>
      <div className='right-content'>
        <div className='topNav'>
          <a
            className={`intro-smaller ${currentSection === 'about' ? 'active' : ''}`}
            onClick={() => executeScroll('about')}
          >
            about
          </a>
          <p className='intro-smaller'>-</p>
          <a
            className={`intro-smaller ${currentSection === 'projects' ? 'active' : ''}`}
            onClick={() => executeScroll('projects')}
          >
            projects
          </a>
          <p className='intro-smaller'>-</p>
          <a
            className={`intro-smaller ${currentSection === 'experience' ? 'active' : ''}`}
            onClick={() => executeScroll('experience')}
          >
            experience
          </a>
        </div>
        <div className='scrollingsubcontent' ref={containerRef}>
          <About ref={aboutRef} />
          <hr/>
          <Projects ref={projectsRef} />
          <hr/>
          <Experience ref={experienceRef} />
        </div>
      </div>
    </div>
  );
}

export default ContentRight;
